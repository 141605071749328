<template>
    <PageLayout>
        <template>
            <div class="d-flex w-100 justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ filter_show ? $t('search_deal') : $t('deal.self') }}</h1>
                </div>
                <div class="text-right">
                    <v-btn icon>
                        <v-icon @click="$router.push({name: 'deal', params:{id: business_process}})">
                            mdi-view-headline
                        </v-icon>
                    </v-btn>
                    <v-btn icon @click="$router.push({name: 'deal.kanban', params:{id: business_process}})">
                        <v-icon>
                            mdi-format-columns
                        </v-icon>
                    </v-btn>
                    <Btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        :disabled="userBlock"
                        :to="{name: 'deal.create'}"
                        color="secondary"
                        :title="$t('deal.add')"
                    >
                        {{ $t('deal.add') }}
                    </Btn>
                    <Teleport to="#app" v-else>
                        <div class="fab__wrapper">
                            <Btn
                                fab
                                :disabled="userBlock"
                                :to="{name: 'deal.create'}"
                                :title="$t('deal.add')"
                                color="secondary"
                            ><v-icon>mdi-plus</v-icon></Btn>
                        </div>
                    </Teleport>
                </div>
            </div>
            <v-container fluid>
                <div class="kanban">
                    <div
                         v-for="(item, index) in deal_statusItems"
                           :key="item.slug + '_' + index"

                         :class="{'disabled-column': !item.kanban_disabled}">
                        <div>{{item.name}} - {{ item.deal_total }}</div>
                        <v-divider class="my-1 mb-5 my_hr" :style="'border-color:'+item.color"></v-divider>

                        <!--Тут нужно сделать чтобы включить :disabled="!item.kanban_disabled"-->
                        <draggable :disabled="item.kanban_disabled" class="list-group"
                                   :list="item.deals"
                                   group="people"
                                   @change="changeDrag($event,item.id)"
                                   @start="startDrag(item,index)"
                                   @end="endDrag"
                        >

                            <div
                                class="list-group-item"
                                v-for="(element, index) in item.deals"
                                :key="element.number + '_' + index"
                            >
                                <div class="kanban_task" :style="'border-left: 3px solid' +  item.color">
                                    <div class="body-2 kanban_link" @click="showDeal(element)">{{ element.number }}. {{ element.title }}</div>
                                    <v-col cols="12" class="text-right">
                                        <v-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="element.admin.photo !== '/img/avatar.png'" :src="element.admin.photo" :alt="element.admin.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{'settingsIcon'}}
                                            </v-icon>
                                        </v-avatar>
                                    </v-col>
                                </div>
                            </div>

                        </draggable>

                    </div>
                </div>


            </v-container>
        </template>
        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5" :disabled="userBlock" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

            <v-list class="filter_left" v-if="businessProcessItems.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group v-model="group3" :ripple="false" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('business_processes') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in businessProcessItems" :key="i" link :to="item.url" :class="{'active': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group2" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link :to="item.url" :class="{'active': isSameRouteWithQueryParams($route, item.url)}" v-show="item.can">
                        <v-list-item-title v-if="item.can" v-text="item.title" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-if="item.can" v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>
</template>
<script>

    import {mapGetters} from "vuex"
    import draggable from "vuedraggable";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import FilterView from "@/plugins/mixins/FilterView";
    import NavMixin from "@/plugins/mixins/Nav";
    import Btn from "@/components/Form/Btn.vue";

    export default {
        name: "CalendarKanban",
        mixins: [
            FilterView,
            NavMixin
        ],
        components: {
            draggable,
            PageLayout,
            Btn,
        },
        data() {
            return {
                loading: false,
                drag: false,
                deal_statusItems: [],
                task_status: null,
                page: 1,
                get_scroll: 1,
                perPage: 15,
                subordinates: false,
                business_process: null,
                businessProcessItems: [],
                group3: true,
                group2: true,
                filter_show: false,
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'BusinessProcessId', 'userBlock']),
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            Additional: function () {
                return [
                    {
                        'title': this.$t('my_deal'),
                        'count': '-',
                        'action': 'all',
                        'can': 1,
                        'url': {
                            path: `/deal_kanban/${this.$route.params.id}`,
                            query: {
                                filter: {
                                    everything_except_completed_canceled: {
                                        eq: 1
                                    },
                                    outbox: {
                                        eq: '1'
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('inbox'),
                        'count': '-',
                        'action': 'all',
                        'can': 1,
                        'url': {
                            path: '/deal_kanban/' + this.$route.params.id,
                            query: {
                                filter: {
                                    inbox: {
                                        eq: '1'
                                    },
                                    everything_except_completed_canceled: {
                                        eq: 1
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('favorite'),
                        'count': '-',
                        'action': 'favorite',
                        'can': 1,
                        'url': {
                            path: '/deal_kanban/' + this.$route.params.id,
                            query: {
                                filter: {
                                    favorite: {
                                        eq: '1'
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('filter.field.only_completed'),
                        'count': '-',
                        'action': 'only_completed',
                        'can': 1,
                        'url': {
                            path: '/deal_kanban/' + this.$route.params.id,
                            query: {
                                filter: {
                                    only_completed: {
                                        eq: '1'
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('filter.field.only_canceled'),
                        'count': '-',
                        'action': 'only_canceled',
                        'can': 1,
                        'url': {
                            path: '/deal_kanban/' + this.$route.params.id,
                            query: {
                                filter: {
                                    only_canceled: {
                                        eq: '1'
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('all_deals'),
                        'count': '-',
                        'action': 'all',
                        'can': 1,
                        'url': {
                            path: `/deal_kanban/${this.$route.params.id}`,
                            query: {
                                filter: {
                                    everything_except_completed_canceled: {
                                        eq: 1
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('subordinates'),
                        'count': '-',
                        'action': 'admin_all',
                        'can': this.can(['director', 'administrator', 'accountant']),
                        'url': {
                            path: '/deal_kanban/' + this.$route.params.id,
                            query: {
                                filter: {
                                    all: {
                                        eq: '1'
                                    }
                                }
                            }
                        }
                    },
                ]
            }
        },

        async mounted() {


            // Ждём, пока Vue рендерит DOM и получаем родителя
            this.$nextTick(() => {
                const container = this.$parent.$refs.mainContent; // Доступ к ref родителя
                if (container) {
                    container.addEventListener("scroll", this.handleScroll, { passive: true });
                }
            });

            this.init();

        },


        methods: {
            async init() {
                this.business_process = parseInt(this.$route.params.id);
                if(this.business_process <= 0 ){
                    this.business_process = this.BusinessProcessId
                }
                await this.getBusinessProcesses();
                if(this.business_process > 0 ) {
                    await this.getDealStatus()
                    await this.getItems();
                }
            },
            getItems() {
                this.page = 1;
                this.getDeals()
            },
            async handleScroll() {

                const container = this.$parent.$refs.mainContent; // Берём родительский div
                if (!container) return;

                const scrollTop = container.scrollTop;
                const scrollHeight = container.scrollHeight;
                const clientHeight = container.clientHeight;

                if (scrollTop + clientHeight >= scrollHeight - 1 && this.get_scroll === 1) {
                    this.page++;
                    await this.getDeals();
                }
            },


            startDrag(item, index){
                // for (const value of this.deal_statusItems) {
                //     if (item.where_move.indexOf(value.id) !== -1) {
                //             value.kanban_disabled =  true;
                //     }
                //     else {
                //         value.kanban_disabled = false
                //     }
                // }
            },
            endDrag(){
                for (const value of this.deal_statusItems) {
                    value.kanban_disabled = true
                }
            },
            changeDrag(evt, deal_status) {
                if(evt && evt.added && evt.added.element && evt.added.element.uuid){
                    this.editStatus(evt.added.element.uuid, deal_status)
                }
            },

            searchTask(){
                this.page = 1;
                this.getCases();

            },
            async getDealStatus() {
                this.loading = true
                let params = {}
                params.business_process = this.business_process;
                await this.$http
                    .get("admin/deal_status", {
                        params: params,
                    })
                    .then(res => {
                        this.deal_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.deal_statusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getDeals() {
                this.get_scroll = 0;
                for (const value of this.deal_statusItems) {
                    this.loading = true;
                    let params = {};
                    params.sortBy = 'id';
                    params.sortDir = 'desc';
                    params.perPage = this.perPage;
                    params.page = this.page
                    params.business_process = this.business_process;
                    params.deal_status = value.id;

                    let add_filter = '';
                    if(this.subordinates === true){
                        add_filter = '?fltr[all][eq]=1';
                    }

                    if (this.filterValues) {
                        params.fltr = this.filterValues;
                    }

                    await this.$http
                        .get("admin/deal"+add_filter, {
                            params: params,
                        })
                        .then(res => {
                            if(this.page === 1){
                                this.get_scroll = 1;
                                value.deals = res.body.data
                                value.deal_total = res.body.meta.total;
                            }
                            else {
                                for (const item of res.body.data) {
                                    this.get_scroll = 1;
                                    value.deals.push(item);
                                }
                            }
                        })
                        .catch(err => {
                            this.deals = []
                            this.$toastr.error(this.$t('failed_to_get_list_deals'))
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async editStatus(deal_uuid, deal_status) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])

                }
                formData.append('deal_status_id', deal_status)
                formData.append('deal_uuid', deal_uuid)

                await this.$http
                    .put(`admin/set/deal/status`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            showDeal(item) {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        id: item.uuid
                    }
                })
            },

            setSubordinates(){
                for (const value of this.deal_statusItems) {
                    value.deals = []
                }
                this.page = 1;
                if(this.subordinates === false){
                    this.subordinates = true;
                }
                else{
                    this.subordinates = false;
                }
                this.getDeals();
            },
            async getBusinessProcesses() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/business_process", {
                        params: params,
                    })
                    .then(res => {
//                        this.businessProcessItems = res.body.data

                        this.businessProcessItems = res.body.data.map(item => {
                            return {
                                ...item,
                                url: {
                                    path: `/deal_kanban/${item.id}` ,
                                }
                            }
                        })


                    })
                    .catch(err => {
                        this.businessProcessItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },

            async changeBusinessProcesses(){
                await this.getDealStatus()
                await this.getDeals()
            },

        }
    }
</script>

<style>
.kanban{
    flex-grow: 1;display:flex;margin-bottom: 1rem; overflow: auto
}
.kanban > div{
    flex-shrink: 0;width: 320px;padding: 10px 10px 0;overflow-y: auto;margin-right: 20px;
}
.kanban_task{
    -webkit-box-shadow: 0 0 2px 0 red;
    box-shadow: 0 0 2px 0 #b3b3b3,0 2px 2px 0 #b3b3b3;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 12px 16px;
    position: relative;
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    -o-transition: box-shadow .3s;
    cursor: all-scroll;
    min-height: 40px;
    border-radius: 3px;

}
.kanban_link{
    cursor: pointer;
}
.kanban_link:hover{
    color: #64B5F6;
}
.sortable-ghost{
    min-height: 40px;
    opacity: 0.5;
    z-index: 100;
    margin-bottom: 15px;
    border: dashed 2px;
    -webkit-box-shadow: none;
    box-shadow:none;
    border-radius:0;
    -webkit-box-sizing: unset;
    box-sizing: unset;
    padding:0;
}
.sortable-ghost div{
    -webkit-box-shadow: none;
    box-shadow:none;
    min-height: 40px;
    border: 0 !important;

}
.sortable-ghost div div{
    visibility: hidden;
}
.disabled-column {
    opacity: 0.5; /* Затемняет запрещённые колонки */
    pointer-events: none; /* Блокирует перетаскивание */
}
</style>
